import { extend, localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import {
  alpha as rule_alpha,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  between as rule_between,
  confirmed as rule_confirmed,
  digits as rule_digits,
  double as rule_double,
  email as rule_email,
  integer as rule_integer,
  length as rule_length,
  min as rule_min,
  regex as rule_regex,
  required as rule_required,
} from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import {
  validatorNumber,
  validatorPassword,
  validatorUrlValidator,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const double = extend('double', rule_double)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const password = extend('password', {
  validate: validatorPassword,
  message:
    'Ihr {_field_} muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Ziffer enthalten',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL ist ungültig',
})

export const number = extend('number', {
  validate: validatorNumber,
  message: '{_field_} muss eine Zahl sein.',
})

localize('de', de)
